// Placeholder to allow defining custom styles that override everything else.
// (Use `_sass/minima/custom-variables.scss` to override variable defaults)

.wrapper {
  width: 100%;
}
.left {
  float: left;
  width: 40%;
}

.middle {
    float:left;
  width: 5%;
}
.right {
  float: left;
  width: 55%;
}

@include media-query($on-palm) {
  .wrapper {
    display: flex;
    flex-direction: column-reverse;
  }
  .right {
    width: 100%;
  }
  .left {
    width: 100%;
  }
}

@include media-query($on-large) {
  .wrapper {
    margin: 0 auto;
  }
}

a {
  color: #228b22;
  text-decoration: none;
}

a:visited {
  color: #8fbc8f;
}

p,
li {
  font-size: 12px;
}
span {
  font-size: 10px;
}

h2 {
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px dotted grey;
}
h3 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

h3,
h5 {
  margin-bottom: 0px;
}

.profile-pic {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: 150px;
  width: 150px;
  border-radius: 5%;
}

.wrapper {
  width: 100%;
}

p {
  font-size: 12px;
}

// make h3 bigger
h3 {
  font-size: 16px;
}



// add margin between left and right
.left {
  margin-right: 30px;
}


span {
  display: inline-block;
  vertical-align: middle;
}

// move span a few pixels down from the line
span {
  font-size: 12px;
}

// underline h7 green


.no-margin {
    margin-bottom: 0px;
}

h3 {
    font-size: 14px;
}

// make h7 green
h7 {
    font-size: 12px;
    font-weight: bold;
}


// center wrapper on screen
// remove bottom margin
.big {
    font-size: 16px;
    margin-bottom: 10px;
    // make italics
    // make text align right
    // remove padding
    padding: 0px;

}

.boy { 
    margin-bottom: 0px !important;
    font-size: 16px;
    // make italics
    font-style: italic;

}

.bigger {
    font-size: 18px;
    font-weight: bold;
}


// select links in lit for color
.lit {

    // make ul elements have no padding and different bullet
    // make link color darker
    a {
        // underline link
        text-decoration: underline;
        font-size: 14px;
    }
}

span {
    font-size: 10px;
    font-weight: normal;
}

h5 {
    font-style: italic;
    margin-bottom: 8px;
}
// just make the underline green nothing else 
// make it the same green as the others and dotted line

h3 {
    font-size: 16px;
}
.flat li {
    font-size: 14px !important;
}

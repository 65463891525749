@charset "utf-8";

// Define defaults for each variable.

$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Segoe UI Symbol", "Segoe UI Emoji", "Apple Color Emoji", Roboto, Helvetica,
  Arial, sans-serif !default;
$code-font-family: "Menlo", "Inconsolata", "Consolas", "Roboto Mono",
  "Ubuntu Mono", "Liberation Mono", "Courier New", monospace;
$base-font-size: 16px !default;
$base-font-weight: 400 !default;
$small-font-size: $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit: 30px !default;

$table-text-align: left !default;

// Width of the content area
$on-palm: 1000px !default;
$on-laptop: 30000px !default;

$on-medium: $on-palm !default;
$on-large: $on-laptop !default;


// Use media queries like this:
 @mixin media-query($on-large) {
   .wrapper {
       maring: auto 0;
   }
 }
// Notice the following mixin uses max-width, in a deprecated, desktop-first
// approach, whereas media queries used elsewhere now use min-width.
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: #{$ratio}rem;
}

// Import pre-styling-overrides hook and style-partials.
@import "minima/custom-variables",
  // Hook to override predefined variables.
    "minima/base",
  // Defines element resets.
    "minima/layout",
  // Defines structure and style based on CSS selectors.
    "minima/custom-styles";

// Placeholder to allow overriding predefined variables smoothly.

// Width of the content area
$content-width: 1400px !default;

$on-palm: 1200px !default;
$on-laptop: 1800px !default;

$on-medium: $on-palm !default;
$on-large: $on-laptop !default;
